import React from 'react';

const BoonTables = ({ className, effect, drawback }) => {
  
  const tables = {
    barbarian: {
      title: "Size Instability Table",
      description: "Roll on this table when you use your boon ability. The effect lasts until the next day.",
      rows: [
        { roll: "1", effect: "Tiny: Your Strength becomes 4, but your Dexterity increases by 4" },
        { roll: "2-3", effect: "Small: Your Strength is reduced by 2, and your weapon damage dice are reduced by one step" },
        { roll: "4-5", effect: "Medium: No size change, but you're overwhelmed by intense emotions, imposing disadvantage on Wisdom checks" },
        { roll: "6-7", effect: "Large: You have disadvantage on Dexterity checks and saving throws, but your melee attacks deal an extra 1d4 damage" },
        { roll: "8", effect: "Huge: Your speed is halved, and you have disadvantage on attack rolls and your max HP increases by your barbarian level" },
        { roll: "9", effect: "Your size changes at inopportune times, imposing disadvantage on Stealth checks" },
        { roll: "10", effect: "You can change size at will as a bonus action" }
      ]
    },
    bard: {
      title: "Genre Shift Table",
      description: "Roll on this table when you use your boon ability.",
      rows: [
        { roll: "1", effect: "Death Metal: Your inspiration dice only grant necrotic damage; your healing spells deal damage instead" },
        { roll: "2-3", effect: "Country: Disadvantage on Intelligence checks, but advantage on Animal Handling" },
        { roll: "4-5", effect: "Classical: You can only speak in rhyming couplets; failure to do so negates your spells" },
        { roll: "6-7", effect: "Punk Rock: Advantage on Intimidation, but disadvantage on all other Charisma checks" },
        { roll: "8", effect: "Rap Battle: You must make a Charisma (Performance) check before casting any spell; on a failure, the spell fizzles" },
        { roll: "9", effect: "Opera: Your voice becomes thunderous. Stealth checks are made at disadvantage, but you gain advantage on Persuasion checks" },
        { roll: "10", effect: "EDM: You can cast Thunderwave at will, but you have disadvantage on concentration checks" }
      ]
    },
    cleric: {
      title: "Divine Prank Table",
      description: "Roll on this table when you cast a spell of 1st level or higher. The effect lasts for 1 hour.",
      rows: [
        { roll: "1", effect: "Your holy symbol becomes sarcastic, heckling you whenever you use it" },
        { roll: "2-3", effect: "Your spiritual weapon manifests as an embarrassing object (DM's choice)" },
        { roll: "4-5", effect: "Your healing spells smell strongly of rotten eggs" },
        { roll: "6-7", effect: "Your voice switches to that of your deity, but they only want to tell bad jokes" },
        { roll: "8", effect: "Every time you channel divinity, you grow a long beard that vanishes after a minute" },
        { roll: "9", effect: "Your spells are accompanied by loud, heavenly laughter" },
        { roll: "10", effect: "You can cast Thaumaturgy at will, but you can't control its effects" }
      ]
    },
    druid: {
      title: "Elemental Mood Swing Table",
      description: "Roll on this table at the start of your turn. The effect lasts until the start of your next turn.",
      rows: [
        { roll: "1", effect: "Flames erupt from your footsteps, igniting flammable objects you touch" },
        { roll: "2-3", effect: "A personal rain cloud forms over you, imposing disadvantage on Perception checks" },
        { roll: "4-5", effect: "Gusts of wind blow your equipment around; you have disadvantage on attack rolls" },
        { roll: "6-7", effect: "The ground trembles beneath you; you must make a DC 10 Dexterity save each turn or fall prone" },
        { roll: "8", effect: "Your body becomes partially gaseous; you have resistance to nonmagical damage but vulnerability to magical damage" },
        { roll: "9", effect: "Plants rapidly grow around your feet, reducing your speed by half" },
        { roll: "10", effect: "You can control the weather in a 10-foot radius around you" }
      ]
    },
    monk: {
      title: "Drunken Master Syndrome Table",
      description: "Roll on this table when you use a ki point. The effect lasts for 1 minute.",
      rows: [
        { roll: "1", effect: "You hiccup uncontrollably, imposing disadvantage on Stealth checks and concentration saves" },
        { roll: "2-3", effect: "Your movements become erratic; you must use your reaction to move 5 feet in a random direction after any creature's turn" },
        { roll: "4-5", effect: "You slur your speech, imposing disadvantage on Charisma checks and spell attacks requiring verbal components" },
        { roll: "6-7", effect: "Your vision doubles; you have disadvantage on attack rolls and Wisdom (Perception) checks" },
        { roll: "8", effect: "You become overly friendly; you can't take hostile actions unless you succeed on a DC 15 Wisdom save" },
        { roll: "9", effect: "Your balance is off; your speed is reduced by 10 feet, and you have disadvantage on Dexterity saves" },
        { roll: "10", effect: "You gain advantage on Dexterity saves, but you must use your action to take the Dodge action each turn" }
      ]
    },
    paladin: {
      title: "Righteous Randomness Table",
      description: "Roll on this table when you use your Divine Sense or Channel Divinity. The effect lasts until you finish a long rest.",
      rows: [
        { roll: "1", effect: "You must loudly proclaim your every action before doing it" },
        { roll: "2-3", effect: "You become obsessed with cleanliness, spending an hour cleaning yourself and your equipment after every combat" },
        { roll: "4-5", effect: "You must attempt to convert everyone you meet to your faith" },
        { roll: "6-7", effect: "You can only speak in religious platitudes; make a DC 15 Charisma save to say anything else" },
        { roll: "8", effect: "Your Divine Smite only functions if you declare a heroic one-liner first" },
        { roll: "9", effect: "You glow with holy light, imposing disadvantage on Stealth checks and making you visible in darkness" },
        { roll: "10", effect: "You can use Lay on Hands on yourself as a bonus action, but it only restores 1 hit point" }
      ]
    },
    ranger: {
      title: "Directional Dyslexia Table",
      description: "Roll on this table when you select this boon the effect lasts for ever",
      rows: [
        { roll: "1", effect: "You become convinced that north is whichever way you're facing" },
        { roll: "2-3", effect: "Your companion animal or familiar refuses to follow your directional commands" },
        { roll: "4-5", effect: "You misread all maps; north and south are swapped, as are east and west" },
        { roll: "6-7", effect: "You leave a glowing trail behind you, ruining any attempts at stealth" },
        { roll: "8", effect: "Your favored terrain becomes your least favored; you have disadvantage on related checks" },
        { roll: "9", effect: "You can only walk in straight lines, needing to turn at 90-degree angles" },
        { roll: "10", effect: "You can innately sense true north, but it overwrites your sense of direction entirely" }
      ]
    },
    sorcerer: {
      title: "Sorcerous Hiccups Table",
      description: "Roll on this table when you cast a spell of 1st level or higher. The effect occurs immediately.",
      rows: [
        { roll: "1", effect: "You teleport to a random space within 20 feet, chosen by the DM" },
        { roll: "2-3", effect: "You shrink one size category for 1 minute" },
        { roll: "4-5", effect: "Your hair grows rapidly and changes color, imposing disadvantage on Perception checks" },
        { roll: "6-7", effect: "You speak in a random language for 1 minute; if you don't know the language, you can't cast spells with verbal components" },
        { roll: "8", effect: "You float 1 foot off the ground for 1 minute, your speed becomes 5 feet, and you can't take reactions" },
        { roll: "9", effect: "You create a 15-foot-radius zone of silence centered on yourself for 1 minute" },
        { roll: "10", effect: "You can cast Prestidigitation at will for 1 minute, but you can't cast any other spells during this time" }
      ]
    },
    warlock: {
      title: "Patron's Random Demand Table",
      description: "Roll on this table when you use your boon ability. You must complete the task before your next long rest or lose the boon's benefit until you do.",
      rows: [
        { roll: "1", effect: "Betray a secret of someone close to you to a stranger" },
        { roll: "2-3", effect: "Perform a dark ritual at midnight, requiring 100 gp worth of components" },
        { roll: "4-5", effect: "Steal an object of value and leave it at a crossroads" },
        { roll: "6-7", effect: "Don't sleep for 24 hours; instead, chant your patron's name" },
        { roll: "8", effect: "Instigate a conflict between two parties who are currently at peace" },
        { roll: "9", effect: "Pledge a year and a day of service to the next person who says your name" },
        { roll: "10", effect: "Your patron allows you to avoid a demand this time" }
      ]
    },
    wizard: {
      title: "Magical Multiplication Table",
      description: "Roll on this table when you cast a spell that creates or conjures. The effect modifies the spell's outcome.",
      rows: [
        { roll: "1", effect: "The spell creates double the intended effect, but you take force damage equal to twice the spell's level" },
        { roll: "2-3", effect: "The spell's creations are sentient and have opposite goals to yours" },
        { roll: "4-5", effect: "The spell's duration is doubled, but you lose your next turn" },
        { roll: "6-7", effect: "The spell affects an additional random target within range" },
        { roll: "8", effect: "The spell's effect is delayed by 1d4 rounds" },
        { roll: "9", effect: "The spell's components are consumed even if they normally wouldn't be" },
        { roll: "10", effect: "The spell's effect is amplified, but you must succeed on a DC 15 Intelligence save or forget the spell until your next long rest" }
      ]
    }
    };

    const getRelevantTable = () => {
      const classTable = tables[className];

      if (!classTable) return null;

      if (drawback === 'size_instability' && className === 'barbarian') return classTable;
      if (drawback === 'genre_shift' && className === 'bard') return classTable;
      if (drawback === 'divine_prank' && className === 'cleric') return classTable;
      if (drawback === 'elemental_mood_swings' && className === 'druid') return classTable;
      if (drawback === 'drunken_master_syndrome' && className === 'monk') return classTable;
      if (drawback === 'righteous_randomness' && className === 'paladin') return classTable;
      if (drawback === 'directional_dyslexia' && className === 'ranger') return classTable;
      if (drawback === 'sorcerous_hiccups' && className === 'sorcerer') return classTable;
      if (drawback === 'patron_s_demand' && className === 'warlock') return classTable;
      if (drawback === 'magical_multiplication' && className === 'wizard') return classTable;

      return null;
    };

    const relevantTable = getRelevantTable();

    if (!relevantTable) {
      return null;
    }
    return (
      <div>
        <h3 className="text-xl font-bold mb-2">{relevantTable.title}</h3>
        <p className="italic mb-2">{relevantTable.description}</p>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">d10</th>
              <th className="border border-gray-300 px-4 py-2">Effect</th>
            </tr>
          </thead>
          <tbody>
            {relevantTable.rows.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="border border-gray-300 px-4 py-2">{row.roll}</td>
                <td className="border border-gray-300 px-4 py-2">{row.effect}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  export default BoonTables;