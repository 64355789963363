const classDrawbacks = {
  barbarian: [
    { value: 'rage_exhaustion', label: 'Berserker\'s Toll', description: 'When your rage ends, you suffer one level of exhaustion and an irresistible urge to knit sweaters.', temp: -40 },
    { value: 'reckless_vulnerability', label: 'Reckless Vulnerability', description: 'While raging, all attacks against you have advantage, and you feel compelled to compliment your enemies\' fighting techniques.', temp: -50 },
    { value: 'uncontrollable_strength', label: 'Uncontrollable Strength', description: 'Your strength becomes so great that you accidentally destroy normal objects you interact with, including doors, chairs, and eating utensils.', temp: -45 },
    { value: 'primal_appetite', label: 'Primal Appetite', description: 'You must eat twice your body weight in food each day or lose the benefits of a long rest. Your preferred food is now raw meat.', temp: -35 },
    { value: 'emotional_amplification', label: 'Emotional Amplification', description: 'Your emotions become extremely intense. You cry at sunsets, laugh maniacally at jokes, and swoon dramatically when you see cute animals.', temp: -30 },
    { value: 'size_instability', label: 'Size Instability', description: 'Your size fluctuates wildly. Roll a D10 at the start of each day on the Size Table', temp: -55 },
  ],
  bard: [
    { value: 'silence', label: 'Melodic Muteness', description: 'You can\'t speak for 1 minute after using this ability. Instead, you can only communicate through interpretive dance.', temp: -30 },
    { value: 'wild_magic', label: 'Chaotic Chorus', description: 'Roll on the Wild Magic table immediately after using this ability. If the result is normally beneficial, it affects your enemies instead.', temp: -40 },
    { value: 'enchanted_instrument', label: 'Enchanted Instrument', description: 'Your instrument gains sentience and will only play songs that embarrass you in social situations.', temp: -35 },
    { value: 'inspirational_backlash', label: 'Inspirational Backlash', description: 'When you use Bardic Inspiration, you must also grant inspiration to the nearest enemy, who uses it with double the normal die.', temp: -45 },
    { value: 'genre_shift', label: 'Genre Shift', description: 'Your musical style randomly changes every day to a completely different genre, affecting all your bard abilities accordingly.', temp: -50 },
    { value: 'sonic_sensitivity', label: 'Sonic Sensitivity', description: 'You take 1d6 psychic damage whenever a player or player character sings off-key or tells a bad joke.', temp: -55 },
  ],
  cleric: [
    { value: 'divine_test', label: 'Divine Test', description: 'After using this ability, you must succeed on a DC 15 Wisdom saving throw or be transformed into your deity\'s sacred animal for 1d4 hours.', temp: -50 },
    { value: 'holy_vulnerability', label: 'Holy Vulnerability', description: 'For 1 hour after using this ability, you have vulnerability to necrotic damage and an inexplicable craving for unholy water.', temp: -40 },
    { value: 'crisis_of_faith', label: 'Crisis of Faith', description: 'You become convinced that you\'re actually a cleric of a different deity. Your domain spells are replaced with random spells from another domain until your next long rest.', temp: -45 },
    { value: 'divine_prank', label: 'Divine Prank', description: 'Your deity plays a practical joke on you. Roll on the \'Divine Prank Table\' (DM\'s discretion) every time you cast a spell of 1st level or higher.', temp: -35 },
    { value: 'celestial_stalker', label: 'Celestial Stalker', description: 'A low-level celestial becomes infatuated with you and follows you around, constantly offering unwanted advice and trying to \'help\' in combat.', temp: -30 },
    { value: 'holy_glow', label: 'Holy Glow', description: 'You emit bright light in a 30-foot radius and dim light for an additional 30 feet. You can\'t turn this off, making stealth impossible and sleep difficult.', temp: -55 },
  ],
  druid: [
    { value: 'nature_s_wrath', label: 'Nature\'s Wrath', description: 'Plants and stones in a 10-foot radius around you become hostile for 1 minute after using this ability. They don\'t discriminate between friend and foe.', temp: -30 },
    { value: 'wild_shape_lock', label: 'Shape Lock', description: 'You can\'t use Wild Shape for 1 hour after using this ability. If already transformed, you\'re stuck in that form for the duration.', temp: -50 },
    { value: 'beast_magnetism', label: 'Beast Magnetism', description: 'You attract every beast within a mile radius. They follow you around, causing chaos and making stealth impossible.', temp: -45 },
    { value: 'elemental_mood_swings', label: 'Elemental Mood Swings', description: 'Your mood affects the weather in a 100-foot radius around you. Your emotions trigger extreme and rapidly changing weather conditions.', temp: -40 },
    { value: 'overgrowth', label: 'Overgrowth', description: 'Plants rapidly grow on your body. You gain +2 AC but have disadvantage on Dexterity checks and saving throws until you spend an hour grooming yourself.', temp: -35 },
    { value: 'feral_mindset', label: 'Feral Mindset', description: 'You temporarily forget how to speak or read any languages. You can only communicate in animal sounds for 1d4 hours.', temp: -55 },
  ],
  fighter: [
    { value: 'combat_fatigue', label: 'Combat Fatigue', description: 'You have disadvantage on all attack rolls for 1 minute after using this ability. During this time, you narrate your attacks like an overly enthusiastic sports commentator.', temp: -40 },
    { value: 'defensive_gap', label: 'Defensive Gap', description: 'Your AC is reduced by 2 for 10 minutes after using this ability. Additionally, your armor develops a mind of its own and occasionally tries to dodge in the wrong direction.', temp: -30 },
    { value: 'weapon_rebellion', label: 'Weapon Rebellion', description: 'Your weapons become temperamental. Roll a d20 before each attack. On a 1, the weapon refuses to attack and instead tells you a bad pun.', temp: -45 },
    { value: 'strategic_confusion', label: 'Strategic Confusion', description: 'You forget all your combat training. For the next hour, you can only attack using improvised weapons and have disadvantage on all attacks.', temp: -50 },
    { value: 'echo_knight', label: 'Echo Knight Syndrome', description: 'You create an echo of yourself that does the opposite of what you want. It has your stats and acts on your initiative, but the DM controls it.', temp: -55 },
    { value: 'armor_adhesion', label: 'Armor Adhesion', description: 'Your armor fuses to your body. You can\'t doff it, and you have disadvantage on Dexterity (Stealth) checks. However, you can now sleep comfortably in full plate.', temp: -35 },
  ],
  monk: [
    { value: 'ki_drain', label: 'Ki Drain', description: 'You lose all remaining Ki points after using this ability. Until your next short rest, expending Ki causes you to hiccup uncontrollably for 1 minute.', temp: -50 },
    { value: 'inner_turmoil', label: 'Inner Turmoil', description: 'You can\'t use Flurry of Blows or Patient Defense for 10 minutes after using this ability. Instead, you feel compelled to solve every problem with interpretive dance.', temp: -40 },
    { value: 'gravitational_flux', label: 'Gravitational Flux', description: 'Your personal gravity becomes unstable. Make a DC 10 Dexterity saving throw every time you move or fall prone.', temp: -45 },
    { value: 'pacifist_pledge', label: 'Pacifist Pledge', description: 'You become a staunch pacifist for 1 hour. You can only use your actions to dodge, disengage, or give rousing speeches about peace.', temp: -55 },
    { value: 'drunken_master_syndrome', label: 'Drunken Master Syndrome', description: 'You fight as if heavily intoxicated. You have disadvantage on attack rolls but are immune to the charmed and frightened conditions because you\'re too confused to be affected.', temp: -35 },
    { value: 'shadow_comedy', label: 'Shadow Comedy', description: 'Your shadow develops a sentience of its own and constantly tries to make you laugh at inappropriate moments. Make a DC 10 Wisdom saving throw to avoid laughing in serious situations.', temp: -30 },
  ],
  paladin: [
    { value: 'divine_vulnerability', label: 'Divine Vulnerability', description: 'You lose all resistance and immunity to damage for 1 hour after using this ability. During this time, you also develop an allergy to your holy symbol.', temp: -50 },
    { value: 'oath_strain', label: 'Oath Strain', description: 'You can\'t use Channel Divinity for 24 hours after using this ability. You also feel compelled to uphold a ridiculous oath chosen by the DM for the duration.', temp: -40 },
    { value: 'anti_smite', label: 'Anti-Smite', description: 'Your Divine Smite temporarily reverses. It now heals undead and fiends and damages celestials and fey.', temp: -45 },
    { value: 'aura_of_inconvenience', label: 'Aura of Inconvenience', description: 'Your auras now affect your allies negatively and your enemies positively. This lasts until you complete a 1-minute ritual of embarrassing calisthenics.', temp: -55 },
    { value: 'mount_mayhem', label: 'Mount Mayhem', description: 'Any mount you summon or ride becomes comically uncontrollable. It follows your commands, but in the most chaotic and inconvenient way possible.', temp: -35 },
    { value: 'righteous_randomness', label: 'Righteous Randomness', description: 'Your sense of good and evil becomes scrambled. Roll a d6 every time you make a moral decision: odd, you choose good; even, you choose evil.', temp: -30 },
  ],
  ranger: [
    { value: 'natural_enmity', label: 'Natural Enmity', description: 'Beasts and plants are hostile to you for 24 hours after using this ability. Even the grass seems to try tripping you.', temp: -40 },
    { value: 'loss_of_focus', label: 'Loss of Focus', description: 'You lose the benefits of your Favored Enemy and Natural Explorer features for 1 hour after using this ability. Instead, you gain an inexplicable fascination with rocks.', temp: -50 },
    { value: 'arrow_attraction', label: 'Arrow Attraction', description: 'Any arrow or bolt fired within 100 feet of you veers off course to hit you instead, regardless of its original target.', temp: -45 },
    { value: 'beast_speech_impediment', label: 'Beast Speech Impediment', description: 'You can speak with animals at will, but you can only communicate through bad puns and dad jokes. Animals find you very annoying.', temp: -35 },
    { value: 'camouflage_malfunction', label: 'Camouflage Malfunction', description: 'Your attempts at stealth make you more noticeable. You have disadvantage on Dexterity (Stealth) checks, and you glow faintly in colors contrasting to your surroundings.', temp: -30 },
    { value: 'directional_dyslexia', label: 'Directional Dyslexia', description: 'You lose all sense of direction. Whenever you try to navigate, roll a d8 to determine which direction you actually go, with 1 being your intended direction and proceeding clockwise.', temp: -25 },
  ],
  rogue: [
    { value: 'exposed', label: 'Exposed', description: 'You can\'t benefit from being hidden for 10 minutes after using this ability. Additionally, your clothes become fluorescent and flash in dark environments.', temp: -40 },
    { value: 'skill_fatigue', label: 'Skill Fatigue', description: 'You have disadvantage on all skill checks for 1 hour after using this ability. During this time, you narrate all your failed attempts in an overly dramatic fashion.', temp: -50 },
    { value: 'kleptomaniac_curse', label: 'Kleptomaniac Curse', description: 'You must succeed on a DC 15 Wisdom saving throw every time you see a shiny object or attempt to pick someone\'s pocket, even allies.', temp: -45 },
    { value: 'sneak_attack_backfire', label: 'Sneak Attack Backfire', description: 'Whenever you use Sneak Attack, roll a d6. On a 1, you deal the extra damage to yourself instead of the target.', temp: -55 },
    { value: 'shadow_puppetry', label: 'Shadow Puppetry', description: 'Your shadow gains a mind of its own and constantly tries to entertain nearby creatures with shadow puppet shows, giving away your position.', temp: -35 },
    { value: 'dramatic_entrance', label: 'Dramatic Entrance', description: 'You can no longer enter a room quietly. Each time you enter a new area, you must make a flashy entrance complete with theme music (hummed by yourself).', temp: -30 },
  ],
  sorcerer: [
    { value: 'wild_surge', label: 'Wild Surge', description: 'Roll on the Wild Magic table after every spell you cast for the next hour. If the result is normally beneficial, it affects the nearest enemy instead.', temp: -50 },
    { value: 'magical_burnout', label: 'Magical Burnout', description: 'You can only cast cantrips for 10 minutes after using this ability. When you try to cast higher-level spells, they fizzle and produce multicolored bubbles.', temp: -40 },
    { value: 'sorcerous_hiccups', label: 'Sorcerous Hiccups', description: 'You hiccup uncontrollably, causing small magical effects each time (DM\'s choice). Make a DC 10 Constitution saving throw when attempting to cast a spell or it fails.', temp: -45 },
    { value: 'metamagic_mishap', label: 'Metamagic Mishap', description: 'Your Metamagic options randomly change every time you use them. Roll a die to determine which option you use, regardless of whether you know it or not.', temp: -55 },
    { value: 'arcane_displacement', label: 'Arcane Displacement', description: 'Whenever you cast a spell targeting a creature, there\'s a 50% chance it affects a random creature within range instead (including yourself).', temp: -35 },
    { value: 'chromatic_chaos', label: 'Chromatic Chaos', description: 'Your skin constantly shifts through all colors of the rainbow. You have disadvantage on Dexterity (Stealth) checks, and creatures have advantage on Wisdom (Perception) checks to spot you.', temp: -30 },
  ],
  warlock: [
    { value: 'patron_s_demand', label: 'Patron\'s Demand', description: 'Your patron demands an immediate service. Roll on the \'Patron\'s Random Demand Table\' (DM\'s discretion) and complete the task before you can long rest.', temp: -50 },
    { value: 'eldritch_backlash', label: 'Eldritch Backlash', description: 'You take 2d10 force damage after using any Invocation for the next hour. The damage manifests as tiny eldritch tentacles slapping you.', temp: -40 },
    { value: 'pact_weapon_rebellion', label: 'Pact Weapon Rebellion', description: 'Your pact weapon gains sentience and refuses to attack unless you compliment it profusely before each swing.', temp: -45 },
    { value: 'invocation_inversion', label: 'Invocation Inversion', description: 'Your Eldritch Invocations have the opposite effect of what they\'re supposed to do for the next hour.', temp: -55 },
    { value: 'familiar_frenzy', label: 'Familiar Frenzy', description: 'Your familiar becomes hyperactive and mischievous. It follows your commands, but in the most annoying and inconvenient way possible. If you don\'t have a familiar, you gain one.', temp: -35 },
    { value: 'hexed_hexer', label: 'Hexed Hexer', description: 'Whenever you cast Hex, you must also apply its effects to yourself. You have disadvantage on ability checks using the chosen ability.', temp: -30 },
  ],
  wizard: [
    { value: 'arcane_fatigue', label: 'Arcane Fatigue', description: 'You have disadvantage on all Intelligence checks and saving throws for 1 hour after using this ability. During this time, you can only speak in rhyme.', temp: -45 },
    { value: 'spell_lock', label: 'Spell Lock', description: 'You can only cast spells of 1st level for 1 minute after using this ability. Attempting to cast higher-level spells results in you polymorphing into a potted plant for one round.', temp: -50 },
    { value: 'scroll_sickness', label: 'Scroll Sickness', description: 'You become violently ill when reading scrolls or spellbooks. Make a DC 12 Constitution saving throw when attempting to read magical writing or spend your turn retching.', temp: -40 },
    { value: 'components_compulsion', label: 'Components Compulsion', description: 'You feel compelled to use material components for all spells, even those that don\'t require them. These components are always ridiculous and embarrassing to use in combat.', temp: -35 },
    { value: 'magical_multiplication', label: 'Magical Multiplication', description: 'Whenever you cast a spell that creates or conjures something, it creates 1d4 additional copies that you can\'t control. Roll on the Magical Multiplication Table for their effect', temp: -55 },
    { value: 'arcane_amnesia', label: 'Arcane Amnesia', description: 'You forget how to cast a random spell from your spellbook every time you finish a long rest. You remember it again only after successfully casting Identify on yourself.', temp: -30 },
  ],
}

export default classDrawbacks;