import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ClassSelection from './ClassSelection';
import BoonCreator from './BoonCreator';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ClassSelection />} />
        <Route path="/create-boon/:className" element={<BoonCreator />} />
      </Routes>
    </Router>
  );
}

export default App;