import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from './components/ui/card';
import { Button } from './components/ui/button';
import CustomSelect from './CustomSelect';
import BoonPowerBar from './components/BoonPowerBar';
import AnimatedText from './AnimatedText';

export const ContractCard = ({ 
  className, 
  options, 
  effect, 
  setEffect, 
  timesPerDay, 
  setTimesPerDay, 
  castTime, 
  setCastTime, 
  distance, 
  setDistance, 
  drawback, 
  setDrawback, 
  temperature, 
  copyToClipboard, 
  copySuccess, 
  wouldExceed 
}) => (
  <Card className="w-full max-w-3xl mx-auto bg-[#fff3c9] border-2 border-[#8b0000] shadow-xl">
    <CardContent className="p-8 font-['IM_Fell_DW_Pica',serif] text-[#3a3a3a]">
      <h2 className="font-['IM_Fell_DW_Pica_SC',serif] text-[#8b0000] text-4xl font-normal text-center mb-6 border-b-2 border-[#8b0000]">Boon Contract</h2>
      
      <p className=" font-['IM_Fell_DW_Pica_SC',serif] first-letter:float-left first-letter:text-[#8b0000] first-letter:text-5xl first-letter:font-['IM_Fell_DW_Pica_SC',serif] first-letter:mr-1 first-letter:mt-1">On this day, I, Tjandé fey, do hereby grant unto the {className} the following boon:</p>

      <p className="mb-4 font-['IM_Fell_DW_Pica_SC',serif] leading-relaxed">
        The bearer of this contract shall receive the power of
        <CustomSelect 
          options={options.effect}
          value={effect}
          onChange={setEffect}
          calculateTemp={(option) => wouldExceed('effect', option.value)}
          surroundingTextBefore=" "
          surroundingTextAfter=". "
        />
        This power may be invoked
        <CustomSelect 
          options={options.timesPerDay}
          value={timesPerDay}
          onChange={setTimesPerDay}
          calculateTemp={(option) => wouldExceed('timesPerDay', option.value)}
          surroundingTextBefore=" "
          surroundingTextAfter=", "
        />
        requiring
        <CustomSelect 
          options={options.castTime}
          value={castTime}
          onChange={setCastTime}
          calculateTemp={(option) => wouldExceed('castTime', option.value)}
          surroundingTextBefore=" "
          surroundingTextAfter=" "
        />
        to activate, and affecting targets within
        <CustomSelect 
          options={options.distance}
          value={distance}
          onChange={setDistance}
          calculateTemp={(option) => wouldExceed('distance', option.value)}
          surroundingTextBefore=" "
          surroundingTextAfter="."
        />
      </p>

      <p className="mb-4 font-['IM_Fell_DW_Pica_SC',serif] leading-relaxed">
        The nature of this boon is as follows: <AnimatedText
          text={options.effect.find(o => o.value === effect)?.description || 'No effect description available.'}
        />
      </p>

      <p className="mb-4 font-['IM_Fell_DW_Pica_SC',serif] leading-relaxed">
        In exchange for this power, the bearer accepts the following condition: 
        <CustomSelect 
          options={[
            { value: 'none', label: 'none (no drawback)' },
            ...options.drawback.filter(o => o.value !== 'none')
          ]} 
          value={drawback}
          onChange={setDrawback}
          calculateTemp={(option) => wouldExceed('drawback', option.value)}
          surroundingTextBefore=" "
          surroundingTextAfter="."
        />
        {drawback !== 'none' && (
          <span> Specifically: <AnimatedText
            text={options.drawback.find(o => o.value === drawback)?.description || 'No drawback description available.'}
          /></span>
        )}
      </p>

      <p className="mb-6 font-['IM_Fell_DW_Pica_SC',serif] text-[#8b0000] text-xl">The potency of this boon is measured thus:</p>
      <div className="mb-6">
        <BoonPowerBar value={temperature} max={100} />
      </div>

      <p className="mb-4 italic font-['IM_Fell_DW_Pica_SC',serif]">By accepting this boon, the bearer acknowledges and accepts all terms and conditions herein.</p>

      <div className="mt-8 flex justify-between items-center">
        <Link to="/">
          <Button className="bg-[#8b0000] text-white font-['IM_Fell_DW_Pica_SC',serif] hover:bg-[#facf7f] hover:text-[#8b0000] px-4 py-2 rounded">Reject Contract</Button>
        </Link>
        <Button onClick={copyToClipboard} className="bg-[#8b0000] text-white font-['IM_Fell_DW_Pica_SC',serif] hover:bg-[#facf7f] hover:text-[#8b0000] px-4 py-2 rounded">
          Sign and Copy Contract
        </Button>
      </div>
      {copySuccess && <span className="text-[#8b0000] mt-2 block text-center font-['IM_Fell_DW_Pica',serif] italic">{copySuccess}</span>}
    </CardContent>
  </Card>
);

export const BoonSummaryCard = ({ 
  className, 
  options, 
  effect, 
  timesPerDay, 
  castTime, 
  distance, 
  drawback, 
  formatUsage 
}) => (
  <Card className="w-full max-w-3xl mx-auto mt-8 bg-[#fff3c9] border-2 border-[#8b0000] shadow-xl">
    <CardContent className="p-8 font-serif">
      <h3 className="font-['IM_Fell_DW_Pica_SC',serif] text-[#8b0000] text-3xl font-normal mb-2">
        Your {className.charAt(0).toUpperCase() + className.slice(1)} Boon: {options.effect.find(o => o.value === effect)?.label}
        {drawback && drawback !== 'none' && ` of ${options.drawback.find(o => o.value === drawback)?.label}`}
      </h3>
      <div className="bg-[#facf7f] border-l-2 border-r-2 border-[#8b0000] text-[#3a3a3a] p-4 mb-4">
        <p className="font-['IM_Fell_DW_Pica_SC',serif] text-[#8b0000] text-xl">Boon of {className.charAt(0).toUpperCase() + className.slice(1)} Mastery</p>
        <p className="italic mb-2">Prerequisite: {className.charAt(0).toUpperCase() + className.slice(1)} class</p>
        <p className="mb-2 leading-relaxed">
          {formatUsage(options.timesPerDay.find(o => o.value === timesPerDay)?.label, options.castTime.find(o => o.value === castTime)?.label)} you can activate this boon
          {distance !== 'touch' ? ` targeting a creature or area within ${options.distance.find(o => o.value === distance)?.label}` : ''}.
        </p>
        <p className="mt-2 leading-relaxed">
          <AnimatedText 
            text={options.effect.find(o => o.value === effect)?.description || 'No effect description available.'} 
          />
        </p>
        {drawback !== 'none' && (
          <p className="mt-2 leading-relaxed">
            <strong className="font-['IM_Fell_DW_Pica_SC',serif] text-[#8b0000] font-normal">Drawback:</strong> <AnimatedText 
              text={options.drawback.find(o => o.value === drawback)?.description || 'No drawback description available.'} 
            />
          </p>
        )}
      </div>
    </CardContent>
  </Card>
);