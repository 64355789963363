import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const CustomSelect = ({ options, value, onChange, calculateTemp, surroundingTextBefore, surroundingTextAfter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        setHoveredOption(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedOption = options.find(option => option.value === value);

  const handleOptionSelect = (option) => {
    onChange(option.value);
    setIsOpen(false);
    setHoveredOption(null);
  };

  return (
    <div className="inline-block relative font-['IM_Fell_DW_Pica',serif]" ref={wrapperRef}>
      <span className="whitespace-pre-wrap">{surroundingTextBefore}</span>
      <div className="relative inline-block">
        {hoveredOption && hoveredOption.description && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 z-20 p-2 mb-1 bg-[#fffaf0] rounded-md shadow-lg border-l-2 border-r-2 border-[#fff3c9] border-opacity-20 w-[20rem] max-w-2xl">
            {hoveredOption.description}
          </div>
        )}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex text-sm items-center justify-between pl-2 text-[#8b0000] bg-[#fffaf0] border border-[#8b0000]  border-opacity-20 rounded-md hover:bg-[#ffffff] focus:outline-none focus:ring-2 focus:ring-[#8b0000] focus:border-[#8b0000]"
        >
          <span className="mr-2 font-['IM_Fell_DW_Pica_SC',serif]">{selectedOption ? selectedOption.label : 'Select an option'}</span>
          <ChevronDownIcon className="w-5 h-5 text-[#8b0000]" />
        </button>
      </div>
      <span className="whitespace-pre-wrap">{surroundingTextAfter}</span>
      
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-[#fffaf0] rounded-md shadow-lg max-h-60 overflow-auto border-2 border-[#8b0000] border-opacity-20 sm:w-auto sm:min-w-max">
          <div className="sticky top-0 bg-[#ffffff] px-4 py-2 font-['IM_Fell_DW_Pica_SC',serif] border-b-2 border-[#8b0000] border-opacity-20 flex justify-between">
            <span>Effect</span>
            <span>Cost</span>
          </div>
          {options.map((option) => {
            const wouldExceed = calculateTemp(option);
            return (
              <div
                key={option.value}
                className={`px-4 py-2 cursor-pointer hover:bg-[#e5e1d8] flex justify-between items-center whitespace-pre ${wouldExceed ? 'text-red-500' : ''}`}
                onClick={() => handleOptionSelect(option)}
                onMouseEnter={() => setHoveredOption(option)}
                onMouseLeave={() => setHoveredOption(null)}
              >
                <span>{option.label} </span>
                <span>{option.temp}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;