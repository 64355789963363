import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, CardHeader, CardContent } from './components/ui/card';
import BoonTables from './BoonTables';
import classDrawbacks from './ClassDrawbacks';
import classEffects from './ClassEffects';
import { ContractCard, BoonSummaryCard } from './BoonContract';

const BoonCreator = () => {
  const { className } = useParams();
  const [distance, setDistance] = useState('touch');
  const [castTime, setCastTime] = useState('1 action');
  const [timesPerDay, setTimesPerDay] = useState('1/day');
  const [effect, setEffect] = useState('');
  const [drawback, setDrawback] = useState('none');
  const [temperature, setTemperature] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');

  const options = useMemo(() => ({
    distance: [
      { value: 'touch', label: 'Touch', temp: 0 },
      { value: '30ft', label: '30 feet', temp: 20 },
      { value: '60ft', label: '60 feet', temp: 40 },
      { value: 'sight', label: 'Sight', temp: 45 },
      { value: 'anywhere', label: 'Anywhere', temp: 50 }
    ].sort((a, b) => a.temp - b.temp),
    castTime: [
      { value: '1 minute', label: '1 minute', temp: 0 },
      { value: '1 action', label: '1 action', temp: 30 },
      { value: '1 bonus action', label: '1 bonus action', temp: 40 },
      { value: 'reaction', label: 'Reaction', temp: 50 },
      { value: 'free action', label: 'Free action', temp: 65 }
    ].sort((a, b) => a.temp - b.temp),
    timesPerDay: [
      { value: '1/day', label: 'Once per day', temp: 0 },
      { value: '1/short rest', label: 'Once per short rest', temp: 30 },
      { value: '3/day', label: 'Three times per day', temp: 35 },
      { value: 'at will', label: 'At will', temp: 60 }
    ].sort((a, b) => a.temp - b.temp),
    effect: (classEffects[className] || []).sort((a, b) => a.temp - b.temp),
    drawback: [
      { value: 'none', label: 'No drawback', description: '', temp: 0 },
      ...(classDrawbacks[className] || [])
    ].sort((a, b) => a.temp - b.temp),
  }), [className]);

  const maxLengths = useMemo(() => ({
    effect: Math.max(...options.effect.map(o => o.description.length)),
    drawback: Math.max(...options.drawback.map(o => o.description.length))
  }), [options]);

  useEffect(() => {
    setEffect(options.effect[0]?.value || '');
  }, [className, options.effect]);

  const getBoonDescription = () => {
    const effectOption      = options.effect.find(o => o.value === effect);
    const drawbackOption    = options.drawback.find(o => o.value === drawback);
    const distanceOption    = options.distance.find(o => o.value === distance);
    const castTimeOption    = options.castTime.find(o => o.value === castTime);
    const timesPerDayOption = options.timesPerDay.find(o => o.value === timesPerDay);

    return `Boon of ${className.charAt(0).toUpperCase() + className.slice(1)} Mastery: ${effectOption?.label}${drawback !== 'none' ? ` of ${drawbackOption?.label}` : ''}
    Prerequisite: ${className.charAt(0).toUpperCase() + className.slice(1)} class

    ${formatUsage(timesPerDayOption?.label, castTimeOption?.label)} you can activate this boon${distance !== 'touch' ? ` targeting a creature or area within ${distanceOption?.label}` : ''}.
    ${effectOption?.description}

    ${drawback !== 'none' ? `Drawback: ${drawbackOption?.description}` : ''}`;
  };

  const calculateTotalTemp = (newDistance, newCastTime, newTimesPerDay, newEffect, newDrawback) => {
    return (
      options.distance.find(o => o.value === newDistance)?.temp +
      options.castTime.find(o => o.value === newCastTime)?.temp +
      options.timesPerDay.find(o => o.value === newTimesPerDay)?.temp +
      options.effect.find(o => o.value === newEffect)?.temp +
      options.drawback.find(o => o.value === newDrawback)?.temp
    );
  };

  const ConditionalBoonTables = ({ className, effect, drawback }) => {
    const boonTablesContent = BoonTables({ className, effect, drawback });
  
    if (boonTablesContent) {
      return (
        <Card className="w-full max-w-4xl mx-auto mt-8 bg-white border border-[#8b4513] shadow-xl">
          <CardContent className="p-8">
            {boonTablesContent}
          </CardContent>
        </Card>
      );
    }
  
    return null;
  };

  useEffect(() => {
    const newTemp = calculateTotalTemp(distance, castTime, timesPerDay, effect, drawback);
    setTemperature(Math.max(0, newTemp || 0));
  }, [distance, castTime, timesPerDay, effect, drawback, options]);

  const wouldExceed = (optionKey, optionValue) => {
    let newTemp;
    switch (optionKey) {
      case 'distance':
        newTemp = calculateTotalTemp(optionValue, castTime, timesPerDay, effect, drawback);
        break;
      case 'castTime':
        newTemp = calculateTotalTemp(distance, optionValue, timesPerDay, effect, drawback);
        break;
      case 'timesPerDay':
        newTemp = calculateTotalTemp(distance, castTime, optionValue, effect, drawback);
        break;
      case 'effect':
        newTemp = calculateTotalTemp(distance, castTime, timesPerDay, optionValue, drawback);
        break;
      case 'drawback':
        newTemp = calculateTotalTemp(distance, castTime, timesPerDay, effect, optionValue);
        break;
      default:
        newTemp = temperature;
    }
    return newTemp > 100;
  };

  const copyToClipboard = () => {
    const boonDescription = getBoonDescription();
    navigator.clipboard.writeText(boonDescription).then(() => {
      setCopySuccess('Copied to clipboard!');
      setTimeout(() => setCopySuccess(''), 3000);
    }, (err) => {
      console.error('Failed to copy: ', err);
      setCopySuccess('Failed to copy');
    });
  };

  const formatUsage = (times, cast) => {
    if (times === 'at will') return 'At will,';
    if (cast === '1 minute') return `${times}, after 1 minute of preparation,`;
    return `${times}, as ${cast === '1 action' ? 'an' : 'a'} ${cast},`;
  };

  return (
    <div className="min-h-screen bg-[#f4e8d1] py-6 flex flex-col justify-center sm:py-12">
      <ContractCard 
        className={className}
        options={options}
        effect={effect}
        setEffect={setEffect}
        timesPerDay={timesPerDay}
        setTimesPerDay={setTimesPerDay}
        castTime={castTime}
        setCastTime={setCastTime}
        distance={distance}
        setDistance={setDistance}
        drawback={drawback}
        setDrawback={setDrawback}
        temperature={temperature}
        copyToClipboard={copyToClipboard}
        copySuccess={copySuccess}
        wouldExceed={wouldExceed}
        maxLengths={maxLengths}
      />
      
      <BoonSummaryCard 
        className={className}
        options={options}
        effect={effect}
        timesPerDay={timesPerDay}
        castTime={castTime}
        distance={distance}
        drawback={drawback}
        maxLengths={maxLengths}
        formatUsage={formatUsage}
      />

      <ConditionalBoonTables
        className={className}
        effect={effect}
        drawback={drawback}
      />
    </div>
  );
};
export default BoonCreator;