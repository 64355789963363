const classEffects = {
  barbarian: [
    { value: 'rage_boost', label: 'Primal Fury', description: 'Your Rage damage bonus is doubled, and you can Rage an additional number of times equal to your proficiency bonus.', temp: 70 },
    { value: 'titan_strength', label: 'Titanic Strength', description: 'Your Strength score increases by 4, and your maximum for that score becomes 24.', temp: 80 },
    { value: 'unbreakable', label: 'Unbreakable', description: 'While raging, you have resistance to all damage types, including psychic.', temp: 90 },
    { value: 'primal_champion', label: 'Primal Champion', description: 'Your critical hit range expands to 19-20, and your critical hits deal maximum damage.', temp: 85 },
    { value: 'thunderous_blows', label: 'Thunderous Blows', description: 'Your melee attacks deal an additional 1d8 thunder damage for 1 minute.', temp: 60 },
    { value: 'battle_trance', label: 'Battle Trance', description: 'While raging, you have advantage on all attack rolls and saving throws.', temp: 75 },
  ],
  bard: [
    { value: 'bardic_inspiration', label: 'Bardic Inspiration Burst', description: 'You strum a chord so inspiring that it resonates with the very fabric of reality. All allies within range gain a Bardic Inspiration die that lasts for 1 hour and can be used for any roll.', temp: 50 },
    { value: 'song_of_rest', label: 'Instant Lullaby', description: 'You hum a soothing melody that instantly refreshes your allies. The entire party gains the benefits of a short rest in just one round of combat.', temp: 65 },
    { value: 'musical_teleport', label: 'Encore Translocation', description: 'You play a familiar tune, and the music carries you through the ethereal plane. You can teleport to any location where you\'ve previously performed, bringing up to five willing creatures with you.', temp: 80 },
    { value: 'sonic_shockwave', label: 'Thunderous Crescendo', description: 'Your performance reaches a deafening climax, unleashing a wave of sonic energy. All creatures in a 30-foot cone must make a Constitution saving throw or take 8d8 thunder damage and be pushed 20 feet away from you.', temp: 70 },
    { value: 'eternal_encore', label: 'Temporal Reprise', description: 'You play a paradoxical melody that bends time itself. You can repeat your last turn exactly as it happened, including regaining any spell slots you expended.', temp: 90 },
    { value: 'inspirational_remix', label: 'Spell Remix', description: 'Your music weaves into the magical energies around you. You can copy any spell cast within the last minute you have seen and cast it as if it were your own. The spell must be of a level you can cast.', temp: 85 },
  ],
  cleric: [
    { value: 'divine_intervention', label: 'Divine Favor', description: 'Your deity always answers your Divine Intervention', temp: 111 },
    { value: 'holy_aura', label: 'Radiant Presence', description: 'You emanate an aura of divine light. Allies within 30 feet of you have advantage on saving throws and enemies have disadvantage on attack rolls against them.', temp: 80 },
    { value: 'miracle_worker', label: 'Miracle Worker', description: 'you can flip a coin and call heads or tails. If you call it right, you regain all expended spell slots. If you call it wrong, you expend all your spell slots.', temp: 70 },
    { value: 'divine_channeling', label: 'Divine Channeling', description: 'Your Channel Divinity recharges on a short rest, and you can use it twice before a rest is required.', temp: 75 },
    { value: 'blessed_healer', label: 'Blessed Healer', description: 'Your healing spells are maximized, and you regain hit points equal to your cleric level whenever you cast a healing spell on another creature.', temp: 85 },
    { value: 'spiritual_guardian', label: 'Spiritual Guardian', description: 'You can summon a celestial guardian that fights on your behalf. The guardian has stats similar to your spiritual weapon but can also take actions and reactions.', temp: 65 },
  ],
  druid: [
    { value: 'wild_shape_boost', label: 'Primal Shapeshifter', description: 'Your Wild Shape CR limit is increased by 2, and you can use Wild Shape as a bonus action.', temp: 70 },
    { value: 'nature_s_ally', label: "Nature's Ally", description: 'You can cast Animal Friendship and Speak with Animals at will, and animals will always be friendly towards you unless magically compelled otherwise.', temp: 60 },
    { value: 'elemental_mastery', label: 'Elemental Mastery', description: 'You can cast each of the following spells once without expending a spell slot: Burning Hands, Create or Destroy Water, Feather Fall, and Mold Earth.', temp: 80 },
    { value: 'nature_s_wrath', label: "Nature's Wrath", description: 'Your spells that deal acid, cold, fire, lightning, or thunder damage deal an extra 1d8 damage of that type.', temp: 75 },
    { value: 'timeless_body', label: 'Timeless Body', description: 'You age one year for every 10 years that pass, and you can\'t be aged magically. Additionally, you have advantage on death saving throws.', temp: 65 },
    { value: 'beast_speech', label: 'Beast Speech', description: 'You can communicate telepathically with beasts and plants within 120 feet of you. You can also cast Dominate Beast once per long rest without expending a spell slot.', temp: 85 },
  ],
  fighter: [
    { value: 'action_surge_boost', label: 'Relentless Assault', description: 'You can use Action Surge twice per short rest instead of once.', temp: 80 },
    { value: 'combat_mastery', label: 'Combat Mastery', description: 'You gain an additional Fighting Style, and the benefits of your Fighting Styles are doubled.', temp: 70 },
    { value: 'legendary_resilience', label: 'Legendary Resilience', description: 'You have advantage on all saving throws, and you can reroll one saving throw per short rest.', temp: 90 },
    { value: 'extra_attack', label: 'Supreme Warrior', description: 'You gain an additional attack when you take the Attack action.', temp: 85 },
    { value: 'indomitable_will', label: 'Indomitable Will', description: 'You can reroll a failed saving throw.', temp: 75 },
    { value: 'weapon_bond', label: 'Weapon Bond', description: 'You can bond with up to three weapons. You can teleport bonded weapons to your hand as a bonus action, and you can teleport ', temp: 75 },
  ],
  monk: [
    { value: 'ki_overflow', label: 'Ki Overflow', description: 'Your Ki points are doubled, and you regain 1 Ki point at the start of each of your turns.', temp: 80 },
    { value: 'flurry_of_blows_boost', label: 'Thousand Fists', description: 'When you use Flurry of Blows, you can make four unarmed strikes instead of two.', temp: 70 },
    { value: 'enlightened_speed', label: 'Enlightened Speed', description: 'Your movement speed is doubled, and you can run on liquids and vertical surfaces without falling during your turn.', temp: 60 },
    { value: 'diamond_soul', label: 'Diamond Soul', description: 'You have proficiency in all saving throws. Additionally, you can reroll a failed saving throw, but you must use the new roll.', temp: 85 },
    { value: 'empty_body', label: 'Empty Body', description: 'You can turn invisible for 1 minute as an action. During that time, you have resistance to all damage except force damage.', temp: 75 },
    { value: 'perfect_self', label: 'Perfect Self', description: 'When you roll for initiative and have no Ki points remaining, you regain 4 Ki points.', temp: 90 },
  ],
  paladin: [
    { value: 'holy_avenger', label: 'Holy Avenger', description: 'Your weapon attacks deal an extra 2d8 radiant damage to fiends and undead, and you are immune to the frightened condition.', temp: 70 },
    { value: 'aura_boost', label: 'Aura of Divinity', description: 'The range of your auras is increased to 60 feet, and allies within your auras have resistance to all damage.', temp: 90 },
    { value: 'divine_smite_boost', label: 'Empowered Smite', description: 'When you use Divine Smite, you can expend any number of spell slots. The extra damage increases by 1d8 for each slot level above 1st.', temp: 80 },
    { value: 'lay_on_hands_boost', label: 'Divine Touch', description: 'Your Lay on Hands pool is doubled, and you can use it as a bonus action.', temp: 75 },
    { value: 'sacred_weapon', label: 'Sacred Weapon', description: 'As a bonus action, you can imbue your weapon with holy power. For 1 minute, it becomes magical, you add your Charisma modifier to attack rolls, and it emits bright light in a 20-foot radius.', temp: 65 },
    { value: 'divine_health', label: 'Divine Health', description: 'You are immune to disease and poison, and you have advantage on saving throws against spells and other magical effects.', temp: 85 },
  ],
  ranger: [
    { value: 'favored_enemy_boost', label: 'Hunter\'s Bane', description: 'You deal an extra 2d6 damage to your favored enemies, and you have advantage on all ability checks to track them.', temp: 70 },
    { value: 'natural_explorer_boost', label: 'One with Nature', description: 'You can\'t be tracked by nonmagical means, and you ignore difficult terrain.', temp: 60 },
    { value: 'beast_master_boost', label: 'Apex Predator', description: 'Your animal companion\'s HP is doubled, and it can attack twice whenever you use your action to command it to attack.', temp: 80 },
    { value: 'feral_senses', label: 'Feral Senses', description: 'You gain blindsight up to 30 feet. Within that range, you can effectively see anything that isn\'t behind total cover, even if you\'re blinded or in darkness.', temp: 75 },
    { value: 'wild_companion', label: 'Wild Companion', description: 'You can cast Find Familiar without material components. The familiar can be a beast of CR 1 or lower.', temp: 65 },
    { value: 'natures_veil', label: "Nature's Veil", description: 'You can use a bonus action to become invisible until the end of your next turn. You can use this feature a number of times equal to your Wisdom modifier per long rest.', temp: 85 },
  ],
  rogue: [
    { value: 'sneak_attack_boost', label: 'Deadly Precision', description: 'Your Sneak Attack damage increases by 2d6, and you can use Sneak Attack twice per turn.', temp: 90 },
    { value: 'evasion_boost', label: 'Shadow Step', description: 'When you use your Uncanny Dodge or Evasion features, you can teleport up to 30 feet to an unoccupied space you can see.', temp: 80 },
    { value: 'skill_mastery', label: 'Jack of All Trades', description: 'You gain expertise in all skills you\'re proficient with, and you can add half your proficiency bonus (round up) to any ability check you make that doesn\'t already include your proficiency bonus.', temp: 70 },
    { value: 'reliable_talent', label: 'Reliable Talent', description: 'Whenever you make an ability check that lets you add your proficiency bonus, you can treat a d20 roll of 9 or lower as a 10.', temp: 75 },
    { value: 'elusive', label: 'Elusive', description: 'No attack roll has advantage against you while you aren\'t incapacitated.', temp: 85 },
    { value: 'stroke_of_luck', label: 'Stroke of Luck', description: 'If your attack misses a target within range, you can turn the miss into a hit. Alternatively, if you fail an ability check, you can treat the d20 roll as a 20. You can use this feature once per short rest.', temp: 65 },
  ],
  sorcerer: [
    { value: 'metamagic_master', label: 'Metamagic Master', description: 'You learn two additional Metamagic options, and you can use two Metamagic options on the same spell.', temp: 80 },
    { value: 'sorcery_point_boost', label: 'Font of Magic', description: 'Your sorcery points are doubled, and you regain 1 sorcery point at the start of each of your turns.', temp: 90 },
    { value: 'innate_spellcasting', label: 'Innate Spellcasting', description: 'Choose three sorcerer spells of 3rd level or lower. You can cast each of these spells without expending a spell slot.', temp: 70 },
    { value: 'magical_guidance', label: 'Magical Guidance', description: 'When you make an ability check that fails, you can spend 1 sorcery point to reroll the d20, and you must use the new roll.', temp: 65 },
    { value: 'sorcerous_restoration', label: 'Sorcerous Restoration', description: 'You regain 4 expended sorcery points whenever you finish a short rest.', temp: 75 },
    { value: 'arcane_empowerment', label: 'Arcane Empowerment', description: 'When you cast a spell, you can add your Charisma modifier to one damage roll of that spell.', temp: 85 },
  ],
  warlock: [
    { value: 'eldritch_master', label: 'Eldritch Master', description: 'You can use your Mystic Arcanum feature twice for each spell level, and you regain all uses on a short rest.', temp: 90 },
    { value: 'pact_boon_boost', label: 'Greater Pact Boon', description: 'The benefits of your Pact Boon are doubled (e.g., two pact weapons, four invocations for your Book of Shadows, or your familiar can attack twice).', temp: 80 },
    { value: 'patron_s_favor', label: "Patron's Favor", description: 'You can call upon your patron to cast any warlock spell without expending a spell slot.', temp: 70 },
    { value: 'eldritch_versatility', label: 'Eldritch Versatility', description: 'You can change one Eldritch Invocation option every time you finish a short or long rest.', temp: 75 },
    { value: 'dark_one_s_luck', label: "Dark One's Own Luck", description: 'When you make an ability check or saving throw, you can add a d10 to your roll. You can do this three times per short rest.', temp: 65 },
    { value: 'hex_master', label: 'Hex Master', description: 'Your Hex spell no longer requires concentration, and you can maintain it on up to three targets at once.', temp: 85 },
  ],
  wizard: [
    { value: 'spell_mastery_boost', label: 'Arcane Mastery', description: 'You can prepare an additional number of spells equal to your Intelligence modifier, and you have advantage on Constitution saving throws to maintain concentration.', temp: 80 },
    { value: 'arcane_recovery_boost', label: 'Arcane Wellspring', description: 'When you use Arcane Recovery, you recover all expended spell slots of 5th level or lower.', temp: 90 },
    { value: 'signature_spell_boost', label: 'Signature Spellcasting', description: 'You can cast each of your Signature Spells once per short rest without expending a spell slot.', temp: 70 },
    { value: 'spell_sniper', label: 'Spell Sniper', description: 'Your spell attack rolls ignore half cover and three-quarters cover. Additionally, the range of your spells with an attack roll is doubled.', temp: 75 },
    { value: 'arcane_ward', label: 'Arcane Ward', description: 'You have a magical ward that has hit points equal to twice your wizard level + your Intelligence modifier. Whenever you take damage, the ward takes the damage instead. It regains all hit points when you finish a long rest.', temp: 85 },
    { value: 'excrymental_vision', label: 'Excrymental Vision', description: 'You have learned a secret technique of divination that involves gazing into a toilet bowl. when you use a toilet bowl as a focus worth at least 2 gp, you can cast the scrying spell without expending a spell slot or material components. The target of your scrying must be on the same plane of existence as you.', temp: 65 },
  ],
};

export default classEffects;