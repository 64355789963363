import React, { useState, useEffect } from 'react';

const AnimatedText = ({ text }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let index = 0;
    setDisplayText('');

    const intervalId = setInterval(() => {
      setDisplayText((prevText) => {
        if (index < text.length) {
          index++;
          return text.slice(0, index);
        } else {
          clearInterval(intervalId);
          return prevText;
        }
      });
    }, 30); // Adjust timing as needed

    return () => clearInterval(intervalId);
  }, [text]);

  return <span>{displayText}</span>;
};

export default AnimatedText;