import React from 'react';

const BoonPowerBar = ({ value, max = 100 }) => {
  const percentage = Math.min(value / max * 100, 100);
  const barColor = `hsl(${120 - (percentage * 1.2)}, 100%, 50%)`;

  return (
    <div className="relative w-full h-6 bg-gray-200 rounded-full overflow-hidden">
      <div 
        className="h-full transition-all duration-300 ease-out"
        style={{ 
          width: `${percentage}%`,
          backgroundColor: barColor
        }}
      />
      {value > max && (
        <div className="absolute top-0 left-0 w-full h-full animate-pulse bg-red-500 opacity-30" />
      )}
      <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm font-bold text-gray-700">
        {value}%
      </span>
    </div>
  );
};

export default BoonPowerBar;